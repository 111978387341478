import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { GatsbyImage } from "gatsby-plugin-image";
import { normalizeProducts, mq } from '../utils';
import { FaFileDownload } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductImageGallery from '../components/product-image-gallery';
import Section from '../components/section';
import ProductPdf from '../components/product-pdf';

const ProductModal =  styled.div`
  width: 100%;
  padding-top: 20vh;
  .product-info-holder {
    margin-top: 1rem;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 2rem;
    .product-info {
      width: 100%;
      &-title {
        font-weight: 700;
        width: 80%;
      }
      .attribute-value {
        color: ${props => props.theme.colors.grey};
      }
      a.pdf-link {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.colors.primary};
        color: white;
        border-radius: 5px;
        text-decoration: none;
        border: 1px solid;
        padding: 0.5rem 0;
        cursor: pointer;
        svg {
          margin-left: 0.5rem;
        }
      }
      ul {
        width: 100%;
        list-style: none;
        margin: 0;
        height: auto;
        max-width: calc(100vw - 2rem);
        li {
          margin-bottom: calc(1rem / 2);
          display: flex;
          flex-direction: column;
          .inner-data-holder {
            display: inline-block;
            vertical-align: top;
            .inline-image {
              height: 10rem;
              width: 10rem;
            }
          }
          .attribute-title {
            font-weight: 400;
            color: hsla(0,0%,0%,0.8);
          }
        }
      }
    }
  }
  ${mq.m} {
    .product-info-holder {
      grid-template-rows: auto;
      grid-row-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 2rem;
      .product-info {
        ul {
          height: auto;
          list-style: initial;
          padding-left: 1rem;
          li {
            display: list-item;
          }
        }
      }
      .product-info-title {
        font-weight: 600;
        width: 100%;
        color: hsla(0,0%,0%,0.8);
      }
    }

  }
`;

const ProductPost = ({ data, pageContext }) => {
  const { allWcProducts, wordpressWpGmBrands } = data;
  const { category }       = pageContext;
  const [ wordpressProduct ] = normalizeProducts(allWcProducts.edges);
  const [ selectedProduct, setSelectedProduct ] = useState(wordpressProduct);
  const [ slug, setSlug ]  = useState(category);
  
  useEffect(() => {
    if (selectedProduct.acf && selectedProduct.acf.videos) {
      const newImages = [...selectedProduct.images];
      const videos = selectedProduct.acf.videos.split(',');
      videos.map((video, index) => newImages.push({
        id: `${newImages[0].id}-video-${index + 1}`,
        name: `video-${index + 1}`,
        video_url: video,
        localFile: newImages[0].localFile,
      })
      );
      setSelectedProduct({ ...selectedProduct, images: newImages });
    } else {
      setSelectedProduct(selectedProduct);
    }
  }, []); 


  return (
    <Layout 
      siteTitle={data.site.siteMetadata.title}
      logo={wordpressWpGmBrands.information.logo}
      accentColor={wordpressWpGmBrands.information.primary_color}
      backgroundColor={wordpressWpGmBrands.information.background_color}
      >
      <SEO
        title={`${wordpressWpGmBrands.title}`}
        keywords={[wordpressWpGmBrands.title]}
        description={`${wordpressWpGmBrands.title} - ${
          wordpressWpGmBrands.gm_brand_categories
            .filter(category => category.description === 'parent_category')
            .reduce(category => category.name).name
        }`}
        thumbnail={`${data.site.siteMetadata.publicURL}${
          wordpressWpGmBrands.information.logo.localFile.publicURL
        }`}
      />
      <Section id="post-list" className="content">
        <ProductModal 
          blackFont={false}
          >
          {selectedProduct && (
            <div className="product-info-holder">
              <div className="product-image-gallery">
                <ProductImageGallery images={selectedProduct.images} />
              </div>
              <div className="product-info">
                <div
                  className="product-info-title"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: selectedProduct.description }}
                />
                <a
                  href={`https://${data.site.siteMetadata.apiURL}/pdf.php?id=${selectedProduct.sku}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="pdf-link"
                >
                  Descargar PDF
                  <FaFileDownload />
                </a>
                <ul>
                  <li>
                    <span className="attribute-title">Categoría: </span>
                    <span className="attribute-value">
                      {selectedProduct.categories
                        .filter(category => category.slug !== slug)
                        .map(category => category.name.replace('&amp;', '&'))
                        .join(', ')}
                    </span>
                  </li>
                  <li>
                    <span className="attribute-title">Código: </span>
                    <span className="attribute-value">{selectedProduct.sku}</span>
                  </li>
                  {selectedProduct.attributes.map(attribute => (
                    <li
                      className={`${attribute.name
                        .toLowerCase()
                        .split(' ')
                        .join('-')}`}
                      key={`${attribute.id}-${attribute.name}`}
                    >
                      <span className="attribute-title">
                        {attribute.name}
                        {': '}
                      </span>
                      {typeof attribute.value === 'string' && (
                        <span className="attribute-value">{attribute.value}</span>
                      )}
                      {typeof attribute.value === 'object' && attribute.value.sku && (
                        <div className="inner-data-holder">
                          <span className="attribute-value">
                            {`Código: ${attribute.value.sku}`}
                          </span>
                          {attribute.value.images && attribute.value.images.length > 0 && (
                            <GatsbyImage
                              image={attribute.value.images[0].localFile.childImageSharp.gatsbyImageData}
                              className="inline-image"
                              alt={attribute.value.images[0].name} />
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
                { (selectedProduct.acf.pdf_for_product_details && selectedProduct.acf.pdf_for_product_details != "false") && (
                  <ProductPdf 
                    linkPdf={selectedProduct.acf.pdf_for_product_details}
                    buttonName={selectedProduct.acf.pdf_button_name}
                  />
                )}
              </div>
            </div>
          )}
        </ProductModal>
      </Section>
    </Layout>
  );
};

ProductPost.propTypes = {
  pageContext: PropTypes.shape({ category: PropTypes.string, slug: PropTypes.string.isRequired, }).isRequired,
  data: PropTypes.shape({ allWcProducts: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  backgroundColorPrimary: PropTypes.string,
  backgroundButtonColorText: PropTypes.string,
};

ProductPost.defaultProps = {
  backgroundColorPrimary: '#59c4c6',
  buttonBackgroundText: '#FFFFF',
};

export default ProductPost;

export const PostQuery = graphql`query DetalleProductos($id: String!, $category: String) {
  ledexProducts: allWcProducts(
    filter: {status: {eq: "publish"}, categories: {elemMatch: {slug: {eq: "ledex"}}}}
  ) {
    edges {
      node {
        sku
        slug
        attributes {
          name
          options
        }
        images {
          name
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, placeholder: NONE, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  wordpressWpSiteIdentity {
    logo {
      localFile {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: TRACED_SVG, layout: CONSTRAINED)
        }
      }
    }
  }
  wordpressWpGmBrands(slug: {eq: $category}) {
    slug
    title
    content
    gm_brand_categories {
      name
      description
    }
    featured_media {
      localFile {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    information {
      primary_color
      background_color
      about {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 540, layout: CONSTRAINED)
          }
        }
      }
      logo {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
      alt_logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 800, placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
      }
    }
  }
  site {
    siteMetadata {
      title
      apiURL
    }
  }
  allWcProducts(
    filter: {status: {eq: "publish"}, categories: {elemMatch: {slug: {eq: "dexel"}}}, id: {eq: $id}}
  ) {
    edges {
      node {
        id
        name
        menu_order
        sku
        slug
        description
        short_description
        acf {
          videos
          pdf_button_name
          pdf_for_product_details
        }
        categories {
          id
          name
          slug
          menu_order
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 360
                  quality: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        attributes {
          id
          name
          options
        }
        images {
          id
          name
          alt
          src
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 100
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`;
